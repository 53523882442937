import clsx from "clsx"
import React from "react"
import { useIntl } from "react-intl"
import { getVerbUrl } from "../../utils/i18nUrls"
import { Link } from "../Link/Link"

export const ListVerb: React.FC<{
  id: string
  verb: string
  definition: string
}> = props => {
  const intl = useIntl()

  return (
    <div className={clsx("flex", "mt-6")}>
      <Link url={getVerbUrl(intl.locale, props.id)}>
        <p className="font-display text-2xl font-semibold text-blue-500">
          {props.verb}
        </p>
        <p className="text-slate-700 text-sm">
          <span className="text-lg" key={props.definition}>
            {props.definition}
          </span>
        </p>
      </Link>
    </div>
  )
}
